var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.companyselections
    ? _c(
        "b-card",
        { staticClass: "mt-3", attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-card-title",
                { staticClass: "text-left m-0" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        value: "selected",
                        "unchecked-value": "unselcted"
                      },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    [_vm._v("Available Companies")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            staticClass: "card-body mb-0",
            attrs: {
              items: _vm.companyselections,
              fields: _vm.fields,
              outlined: "",
              small: "",
              striped: "",
              hover: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(id)",
                  fn: function(data) {
                    return [
                      _c("b-form-checkbox", {
                        attrs: { disabled: _vm.loading || _vm.isProcessing },
                        on: {
                          input: function($event) {
                            return _vm.$emit("update", _vm.companyselections)
                          }
                        },
                        model: {
                          value: data.item.selected,
                          callback: function($$v) {
                            _vm.$set(data.item, "selected", $$v)
                          },
                          expression: "data.item.selected"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "cell(income)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(data.item.income)) + " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(expense)",
                  fn: function(data) {
                    return [
                      data.item.details && data.item.details.length
                        ? _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showExpenseDetail(data.item)
                                }
                              }
                            },
                            [
                              _c("b-icon-info-square-fill", {
                                attrs: { variant: "info" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(Math.abs(data.item.expense))
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(net)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(data.item.net)) + " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(netpct)",
                  fn: function(data) {
                    return [_vm._v(" " + _vm._s(data.item.netpct) + "% ")]
                  }
                }
              ],
              null,
              false,
              2906081212
            )
          }),
          _vm.selectedDetail
            ? _c(
                "b-modal",
                {
                  attrs: {
                    size: "md",
                    title: "Expense Detail for " + _vm.selectedDetail.company,
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    "ok-only": ""
                  },
                  on: {
                    ok: function($event) {
                      _vm.selectedDetail = null
                    }
                  },
                  model: {
                    value: _vm.showExpenseDetailModal,
                    callback: function($$v) {
                      _vm.showExpenseDetailModal = $$v
                    },
                    expression: "showExpenseDetailModal"
                  }
                },
                [
                  _c(
                    "b-table-simple",
                    [
                      _c(
                        "b-thead",
                        [
                          _c("b-th", [_vm._v("Account")]),
                          _c("b-th", { staticClass: "text-right" }, [
                            _vm._v("Amount")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        _vm._l(_vm.selectedDetail.details, function(d) {
                          return _c(
                            "b-tr",
                            { key: d.name },
                            [
                              _c("b-td", [_vm._v(_vm._s(d.name))]),
                              _c("b-td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(Math.abs(d.balance))
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }